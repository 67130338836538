import React, {useState} from "react";
import Layout from "../components/layout";
import {StaticImage} from "gatsby-plugin-image"

import {CompanyHistory} from "../components/company-history";
import {graphql} from "gatsby";
import SEO from "../components/seo";
import get from "lodash/get";
import GatsbyImage from "gatsby-image";

const link = 'https://www.chinalandlink.com/en/images/about_banner.jpg'

const AboutUs = ({location, data}) => {
  const allKeywords = get(data, 'allContentfulPageKeywords.nodes')
  const allAssets = get(data, 'allContentfulAsset.nodes')

  const findImage = (title) => allAssets.find(x => x.title === title)

  return (
    <Layout location={location}>
      <SEO title={'HomeBest A manufacturer of extension sockets, wall switches and lamps in China.'} keywords={allKeywords[0]?.keywords}/>
      <img src={link} className="img-fluid" alt="..." style={{minHeight: '10rem'}}/>
      <div className="container">
        <div className='my-5'>
          <h1 className='py-3 text-center'>For Home Appliances, You have HomeBest</h1>
          <p className='pb-3'>Founded in 1999, NINGBO LANDLINK INDUSTRIAL TECHNOLOGY CO., LTD is an expert of household electrical accessories and an integrated supply chain supplier, specializing in switches, sockets and LED lighting.</p>
          <StaticImage src="../../static/images/about-1.png" alt="About campony"/>
        </div>
        <div className='my-5'>
          <h1 className='py-3 text-center'>20 years of home appliance production experience</h1>
          <CompanyHistory lang='en' />
        </div>
        <div className='my-5'>
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h3 className='py-3'>Market orientation</h3>
                <p>Through on-the-spot investigations, visiting customers and other approaches, we have an in-depth
                  understanding of the local market trend and the consumption tendency, and develop new market-oriented
                  products for customers. At the same time, with a deep understanding of the standards of the local
                  market, including IEC, CE, RoHS, SASO, ESMA and so on, we can ensure that our products can comply with
                  the standard requirements.</p>
              </div>
              <div className="col-6">
                <h3 className='py-3'>Supply guarantee</h3>
                <p>We have three large production and manufacturing bases to ensure entity production for the three
                  major product lines of switches, sockets and LED lighting. The bases are located in China’s “Yangtze
                  River Delta” and “Pearl River Delta”, adjacent to the industrial chain. With a supply network of more
                  than 800 suppliers, we are able to design and manage integrated delivery schemes according to
                  customers’ requirements, provide supply chain management and services in terms of product design,
                  production and manufacturing, and quality control, and strive to provide customers with high-quality,
                  cheap-price and punctual supply chain security.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <h3 className='py-3'>Excellent goodwill</h3>
                <p>As a trustworthy company, we conduct honest business, boast a good reputation in the industry, and
                  have established extensive and in-depth cooperative relationships with many customers in the Middle
                  East, Africa, Britain and other countries and regions. We attach the greatest importance to our
                  customers’ evaluation and satisfaction.</p>
              </div>
              <div className="col-6">
                <h3 className='py-3'>Win-win cooperation:</h3>
                <p>With a professional, efficient and collaborative team, we actively participate in each of our
                  customers’ businesses, understand and support their marketing strategy, and try to transform from an
                  ordinary supplier to their strategic partner. Through the excellent cooperation, we will really bring
                  return in performance for our customers and partners, and jointly promote the harmony of the
                  society.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='my-5'>
          <h1 className='py-3 text-center'>We Own 2 Factories, 8 Production Lines</h1>
          <p className='pb-3'>Homebest was founded to manufacture better quality home appliances. After 20 years of
            exporting tens of
            billions of products, we are changing the industry and guaranteeing the quality of each product. Our
            state-of-the-art factory supports all this. With years of experience in production, design, testing,
            manufacturing, sales, marketing and design, the talents of our team flow in every component and every
            product.</p>
          <div className="container">
            <div className="row py-3">
              <div className="col-4">
                <img src={findImage("Factory equipment 1").fluid.src} alt={findImage("Factory equipment 1").title}/>
              </div>
              <div className="col-4">
                <img src={findImage("Factory equipment 2").fluid.src} alt={findImage("Factory equipment 2").title}/>
              </div>
              <div className="col-4">
                <img src={findImage("Factory equipment 3").fluid.src} alt={findImage("Factory equipment 3").title}/>
              </div>
            </div>
            <div className="row py-3">
              <div className="col-7">
                <p>
                  Independent&nbsp;design&nbsp;laboratory
                  <br/>
                  Experienced&nbsp;engineer
                  <br/>
                  Anti-overcharge&nbsp;technologySuperb&nbsp;design&nbsp;craftsmanship
                  <br/>
                  Many&nbsp;years&nbsp;of&nbsp;production&nbsp;and&nbsp;export&nbsp;experience&nbsp;has&nbsp;allowed&nbsp;us&nbsp;to&nbsp;have&nbsp;a&nbsp;certain&nbsp;market&nbsp;
                  <br/>
                  scale&nbsp;in&nbsp;the&nbsp;Middle&nbsp;East,&nbsp;South&nbsp;Africa,&nbsp;and&nbsp;Southeast&nbsp;Asia.
                </p>
              </div>
              <div className="col-5">
                <StaticImage src="../../static/images/product-line-people.jpg" alt="About campony"/>
              </div>
            </div>
          </div>
        </div>
        <div className='my-5'>
          <h1 className='py-3 text-center'>We're True Factory</h1>
          <p className='pb-3'>Our team includes technical experts, production managers, quality control managers,
            merchants, and
            manufacturing specialists who believe in:</p>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <StaticImage src="../../static/images/manager-group.png" alt="About campony"/>
              </div>
            </div>
            <div className="row py-4">
              <div className="col-3">
                <StaticImage src="../../static/images/manager-1.png" alt="About campony"/>
              </div>
              <div className="col-3">
                <StaticImage src="../../static/images/manager-2.png" alt="About campony"/>
              </div>
              <div className="col-3">
                <StaticImage src="../../static/images/manager-3.png" alt="About campony"/>
              </div>
              <div className="col-3">
                <StaticImage src="../../static/images/manager-4.png" alt="About campony"/>
              </div>
            </div>
            <div className="row py-3">
              <div className="col-7">
                <h1 className='py-3'>HB exists to let more people see us online</h1>
                <p>Since 2017, our products have been mainly sold in Nigeria, Kuwait, Saudi Arabia, Bahrain and other markets of the Middle East. Our sales amount reaches more than 10 million dollars in 2019, and in 2020 achieves the 50% increase compared to 2019. Click here and see more about HOMEBEST.</p>
              </div>
              <div className="col-5">
                <StaticImage src="../../static/images/let-me-see-you-online.png" alt="About campony"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query ABoutUsQuery {
        allContentfulPageKeywords(filter: {page: {eq: "aboutus"}, node_locale: {eq: "en-US"}}) {
            nodes {
                keywords
                node_locale
                page
            }
        }
        allContentfulAsset( filter: {title: {in: ["Factory appearance", "Product line worker", "Factory equipment 1", "Factory equipment 2", "Factory equipment 3"]}, node_locale: {eq: "en-US"}})
          {
            nodes {
              fluid(quality: 90, maxWidth: 1920) {
                  src
              }
              title
          }
      }
    }
`


export default AboutUs;
